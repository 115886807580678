import React from 'react'
import Helmet from 'react-helmet'
import {queryParamsFromString} from "../helpers";

class KlaviyoForm extends React.Component {
  constructor(props) {
    super(props);
    this.id = queryParamsFromString(this.props.location.search).id
  }

  render() {

    return (
      <>
        <Helmet>
          <meta name="robots" content="noindex" />
          <script async type="text/javascript" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=KKMzhf"></script>
        </Helmet>
        { this.id && (
          <div className={`m-0 ${this.id}`}></div>
        )}
      </>
    )
  }
}

export default KlaviyoForm